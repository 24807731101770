<script>
	import Image from '$lib/Components/Layout/Image/Image.svelte';
	import Section from '$lib/Components/Layout/Section/Section.svelte';

	import dwell from './images/dwell.png?w=85&format=avif;webp';
	import nyt from './images/nytimes.png?w=150&format=avif;webp';
	import thisOldHouse from './images/oldhouse.png?w=100&format=avif;webp';
	import popularMechanics from './images/popularmechanics.png?w=100&format=avif;webp';
	import treeHugger from './images/treehugger.png?w=150&format=avif;webp';

	let { backgroundColor = 'grey', loading = 'lazy', children } = $props();
</script>

<Section {backgroundColor} class="!py-[min(2rem,_10vw)]">
	<ul
		class="logos mb-0 grid list-none grid-flow-row grid-cols-6 place-items-center content-center items-end justify-center gap-y-4 md:grid-cols-5 [&_li]:mb-0"
	>
		<li class="col-span-2 place-self-start md:col-span-1">
			<Image
				src={popularMechanics}
				alt="Popular Mechanics"
				class="h-8 object-contain"
				noMeta
				{loading}
			/>
		</li>
		<li class="col-span-2 md:col-span-1">
			<Image
				src={dwell}
				alt="Dwell"
				class="h-8 object-contain"
				noMeta
				{loading}
			/>
		</li>
		<li class="col-span-2 place-self-end md:col-span-1">
			<Image
				src={thisOldHouse}
				alt="This Old House"
				class="h-8 object-contain"
				noMeta
				{loading}
			/>
		</li>
		<li class="col-span-3 place-self-start md:col-span-1">
			<Image
				src={treeHugger}
				alt="Treehugger"
				class="h-8 object-contain"
				noMeta
				{loading}
			/>
		</li>
		<li class="col-span-3 place-self-end md:col-span-1">
			<Image
				src={nyt}
				alt="The New York Times"
				class="h-8 object-contain"
				noMeta
				{loading}
			/>
		</li>
	</ul>
	{@render children?.()}
</Section>

<style>
	.logos {
		column-gap: clamp(1rem, 4vw, 3rem);
	}
</style>
